.box-shadow-hover:hover {
  filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.50));
}

footer {
  background: transparent; /* Transparent background */
  padding: 0.5rem; /* Reduced padding */
  text-align: center;
  font-family: 'Roboto', Arial, sans-serif; /* Roboto font */
}

footer a {
  color: #D1793B; /* Dark burnt orange color */
  text-decoration: none;
}

.permalinks {
  font-size: 1rem; /* Adjusted font size */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto 0.5rem;
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1.5rem; /* Adjusted icon size */
}

.footer__socials a {
  color: #D1793B; /* Dark burnt orange color */
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
}

.footer__copyright {
  font-size: small;
  margin-bottom: 0.5rem;
  color: #D1793B; /* Dark burnt orange color */
}

/* Other media query rules remain the same */


#mc_embed_signup {
  background: #fff; 
  clear: left; 
  font: 14px Helvetica, Arial, sans-serif; 
  width: 600px;
}

#mc_embed_shell {
  display: flex;
  justify-content: center;
  align-items: center;
}
