@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';



/* width */
body::-webkit-scrollbar {
    width: 8px;
}

/* Track */
body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* New styles for the carousel */
.scroll-smooth {
  scroll-behavior: smooth;
}

.carousel::-webkit-scrollbar {
  height: 8px;
}

.carousel::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.carousel::-webkit-scrollbar-thumb {
  background: #888;
}

.carousel::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
body {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
    color: #000000;
    font-family: 'Roboto', 'Poppins', Arial, sans-serif;
    background: linear-gradient(to top, #55eafb, #f7f7f7);
}

/* For booking containers */

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.shadow-lg {
  box-sizing: border-box;
  flex: 0 1 calc(33.33% - 10px);
  margin-bottom: 15px;
}

@media (max-width: 800px) {
  .shadow-lg {
      flex: 0 1 calc(50% - 10px);
  }
}

@media (max-width: 500px) {
  .shadow-lg {
      flex: 0 1 100%;
  }
}